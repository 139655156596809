import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fit club and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'AT our fit club, we are committed to not just achieving fitness goals but also to fostering a supportive and motivational community.',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:'This fit club has been instrumental in my fitness journey, offering diverse workouts and expert guidance that have significantly improved my health and well-being.',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
